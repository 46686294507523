<template>
    <main id="design" class="design p-relative" :class="store.getters.contextClassNames" >
        <TopBar v-if="!store.getters.isProjectshare"
                :title="translate('inspo.background_designs')"
                :backFunction="()=>navigate('designs')"
                :backLabel="translate('inspo.back_designs')"
        >
            <template v-slot:topbar-end>
                <section class="hide-small-flex" >
                    <Btn :clickFunction="()=>navigate('designs')"
                         :label="translate('inspo.search_designs')"
                         icon="search"
                         secondary
                         :smallerText="store.state.size !== 'large'"
                    />
                    <DesignButtons class="flex"
                                   :editBtn="editBtn"
                                   :shareButton="shareButton"
                                   @shareDesign="shareDesign"
                                   @toggleForm="toggleForm"
                                   @toggleEdit="toggleEdit"
                    />
                </section>
                <section class="show-small-flex" >
                    <Btn :clickFunction="()=>navigate('designs')"
                         :label="translate('inspo.search_designs')"
                         icon="search"
                         :roundOnSmall="store.state.xsmall"
                         :smallerText="store.state.size !== 'large'"
                    />
                </section>
            </template>
        </TopBar>

        <Preview :id="id"
                 :items="filteredDesigns"
                 component="design"
                 :search="route.query.search"
                 :onBackground="previewOnBackground"
        >
            <template v-slot:preview-image>
                <DesignCanvas v-bind="{ design, showFeed, fullNameComp, initFontSizeName, initFontSizeText, selectedNameFont, selectedTextFont }" />
            </template>
            <template v-slot:info-text>
                <template v-if="!store.getters.isProjectshare" >
                    <DesignButtons class="show-small-flex design-buttons-small"
                                   :editBtn="editBtn"
                                   :shareButton="shareButton"
                                   @shareDesign="shareDesign"
                                   @toggleForm="toggleForm"
                                   @toggleEdit="toggleEdit"
                    />
                </template>
                <section class="edit-content-wrapper">
                    <div v-if="editMode && !showFeed" class="edit-content">
                        <section>
                            <section class="info-section content-name" v-if="!project_subject">
                                <p class="info-text" for="fullName">{{ translate("inspo.name_of_deceased") }}</p>
                                <input class="input-light w-100"
                                       type="text"
                                       :placeholder="translate(store.getters.currentImage.dummy_name)"
                                       :value="fullNameComp"
                                       @input="setFullName($event.target.value)"
                                />
                            </section>
                            <section class="flex-horizontal-wrap">
                                <section class="info-section" style="margin-right:0.5em">
                                    <p class="info-text">{{ translate("inspo.image") }}</p>
                                    <div class="rectangles">
                                        <template v-for="image in images" :key="`image${image.id}`" >
                                            <button class="rectangle-wrapper image selectable"
                                                    v-if="(image.id !== 3 || hasProjectSubjectImage) && (![1,2].includes(image.id) || !hasProjectSubjectImage)"
                                                    :class="{selected: image.id === store.getters.getImageId, person: image.id !== 0}"
                                                    @click="selectNewImage(image.id)"
                                            >
                                                <font-awesome-icon class="icon" color="black" height="2em" :icon="['far', image.icon]"/>
                                            </button>
                                        </template>
                                    </div>
                                </section>
                                <section class="info-section nowrap" v-if="store.getters.getImageId>0" >
                                    <p class="info-text">{{ translate("inspo.image_position") }}</p>
                                    <div class="rectangles">
                                        <template v-for="position in image_positions" :key="`imageposition${position.id}`" >
                                            <button class="rectangle-wrapper selectable arrow"
                                                    :class="{selected: position.id === store.getters.getImagePosition}"
                                                    @click="selectNewImagePosition(position.id)"
                                            >
                                                <font-awesome-icon class="icon" color="black" height="2em" :icon="['far', position.icon]"/>
                                            </button>
                                        </template>
                                    </div>
                                </section>
                            </section>
                            <section class="info-section">
                                <p class="info-text">{{ translate("inspo.pageeffect") }}</p>
                                <div class="rectangles">
                                    <button class="rectangle-wrapper selectable" v-for="effect in effects"
                                            :class="{selected: effect.id === store.getters.getEffectId}"
                                            :key="`effect${effect.id}`"
                                            @click="selectNewEffect(effect.id)"
                                    >
                                        <div class="rectangle">
                                            <div :style="getEffectRectangleStyle(effect.name)" ></div>
                                        </div>
                                    </button>
                                </div>
                            </section>
                            <section class="info-section">
                                <p class="info-text">{{ translate("inspo.position") }}</p>
                                <div class="rectangles">
                                    <button class="rectangle-wrapper selectable" v-for="position in positions"
                                            :class="{selected: position.id === store.getters.getPositionId}"
                                            :key="`position${position.id}`"
                                            @click="selectNewPosition(position.id)"
                                    >
                                        <div class="rectangle position-boxes" :style="getPositionStyle(position.name)">
                                            <div class="box"></div>
                                            <div class="box"></div>
                                        </div>
                                    </button>
                                </div>
                            </section>
                            <section class="info-section">
                                <p class="info-text">{{ translate("inspo.fontstyle_name") }}</p>
                                <ChangeFontFamily :fonts="fonts.dead_name"
                                                  :cachedFont="selectedNameFont"
                                                  @fontChange="fontChange($event.data, 'dead_name')"
                                />
                            </section>
                            <section class="info-section">
                                <p class="info-text">
                                    {{ translate("inspo.fontstyle_text") }}
                                </p>
                                <ChangeFontFamily :fonts="fonts.text"
                                                  :cachedFont="selectedTextFont"
                                                  @fontChange="fontChange($event.data, 'text')"
                                />
                            </section>
                            <section class="info-section editor-info">
                                <p class="info-text smaller-text" v-html="nl2br(translate('inspo.edit_info'))" />
                            </section>
                        </section>
                    </div>
                    <div v-else-if="design" class="design-desc-wrapper">
                        <div class="design-desc">
                            <section v-if="canSetPreferredDesign" >
                                <Btn :label="translate('share.set_preferred_design')" :clickFunction="setPreferredDesign" />
                                <br>
                            </section>

                            <section class="info-section">
                                <p class="info-section-heading">{{ translate("inspo.design") }}</p>
                                <p class="info-title" v-if="design.code">
                                    {{ design.code + " - " + design.name }}
                                </p>
                            </section>

                            <div class="spread-select">
                                <button class="choose-bg-wrapper" @click="toggleFeed(false)">
                                    <img v-if="design.cover_preview_url"
                                         :src="design.cover_preview_url"
                                         :class="{ selected: !showFeed }"
                                         class="selectable spread-select-item"
                                         alt=""
                                    >
                                    <img v-else
                                         :src="store.getters.noDesignPath"
                                         :class="{ selected: !showFeed }"
                                         class="selectable spread-select-item nobg-spread"
                                         alt=""
                                    >
                                    <span class="info-text">{{ translate("inspo.cover") }}</span>
                                </button>
                                <button class="choose-bg-wrapper" @click="toggleFeed(true)">
                                    <img v-if="design.feed_preview_url"
                                         :src="design.feed_preview_url"
                                         :class="{ selected: showFeed }"
                                         class="selectable spread-select-item"
                                         alt=""
                                    >
                                    <img v-else
                                         :src="store.getters.noDesignPath"
                                         :class="{ selected: showFeed }"
                                         class="selectable spread-select-item nobg-spread"
                                         alt=""
                                    >
                                    <span class="info-text">{{ translate("inspo.feed") }}</span>
                                </button>
                            </div>
                            <section v-if="showFeed && design.feed_preview_url" class="rectangles typography">
                                <button class="rectangle-wrapper selectable" v-for="typography in typographies"
                                        :key="`typography${typography.id}`"
                                        @click="selectNewTypography(typography.id)"
                                        :class="{selected: typography.id === store.getters.getTypographyId}"
                                >
                                    <img :src="`${store.state.sourceUrl}/images/${typography.icon}`" alt="">
                                </button>
                            </section>

                            <template v-if="design?.categories?.length || design.searchwords" >
                                <hr />

                                <section v-if="design.categories?.length" class="info-section tags-wrapper">
                                    <p class="info-section-heading">
                                        {{ translate("inspo.category_names") }}
                                    </p>
                                    <div class="tags" >
                                        <p v-for="category in design.categories"
                                           :key="category.id"
                                           @click="categoryClicked(category)"
                                        >
                                            {{ category.name }}
                                        </p>
                                    </div>
                                </section>
                                <section v-if="design.searchwords" class="info-section tags-wrapper" >
                                    <p class="info-section-heading">{{ translate("inspo.searchwords") }}</p>
                                    <div class="tags">
                                        <p v-for="searchword in searchwords"
                                           :key="searchword"
                                           @click="search(searchword)"
                                        >
                                            #{{ searchword }}
                                        </p>
                                    </div>
                                </section>
                            </template>
                        </div>
                    </div>
                </section>
            </template>
        </Preview>

        <ContactAgencyForm v-if="showForm"
                           :designId="design.id"
                           @hide="showForm=false"
        />
        <ShareDesign v-if="showShare"
                     :designId="design.id"
                     @hide="showShare=false"
        />
    </main>
</template>

<script>

import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from "vue";
import { useRouter, useRoute } from "vue-router";

import store from "../store/index";

import {mapGetters} from "@/libs/storeHelper";
import { typographies, effects, positions, images, image_positions, getEffectStyle, getPositionStyle } from "@/config";
import { translate, is_true, nl2br, updateRouteQuery, scrollToTop} from "@/libs/helper";

import Btn from "../components/Btn.ce.vue";
import Preview from "../components/Preview.ce.vue";
import TopBar from "../components/TopBar.ce.vue";
import ChangeFontFamily from "../components/ChangeFontFamily.ce.vue";
import ContactAgencyForm from "../components/ContactAgencyForm.ce.vue";
import ShareDesign from "../components/ShareDesign.ce.vue";
import DesignButtons from '../components/DesignButtons.ce.vue';
import DesignCanvas from "@/components/DesignCanvas.ce.vue";

export default {
    components: {
        DesignCanvas,
        Btn,
        Preview,
        TopBar,
        ChangeFontFamily,
        ContactAgencyForm,
        DesignButtons,
        ShareDesign,
    },
    setup() {
        const router = useRouter();
        const route = useRoute()

        const { filteredDesigns } = mapGetters();

        const initFontSizeName = ref(0);
        const initFontSizeText = ref(0);

        const previewOnBackground = ref(false);

        const settings = computed(() => {
            return store.state.settings
        })

        const project = computed(() => {
            return store.state.project;
        });

        const project_subject = computed(()=>{
            if(project.value?.subject_firstname) {
                let full_name = project.value.subject_firstname;
                if(project.value.subject_lastname)
                    full_name += " "+project.value.subject_lastname;
                return full_name;
            }
            return null;
        });

        const hasProjectSubjectImage = computed(()=>{
            return project.value?.subject_image && project.value.subject_image !== 'null';
        });

        // EDIT MODE
        const editMode = ref(false);
        const toggleEdit = () => {
            updateRouteQuery(route, router, { editMode: !editMode.value, showFeed: false });
        };
        const editBtn = computed(() => {
            if (editMode.value)
                return { icon: "check", text: translate("inspo.save") };
            return { icon: "pen-to-square", text: translate("inspo.edit") };
        });
        // END EDIT MODE

        // FEED
        const showFeed = computed(()=>{
            return store.state.design.showFeed;
        });

        const toggleFeed = (val) => {
            updateRouteQuery(route, router, { showFeed: val });
        }
        // END FEED

        // DESIGN
        const id = route.params.id;
        const design = computed(() => {
            return filteredDesigns.value.find((el) => el.id == id);
        });

        const searchwords = computed(() => {
            let words = design.value.searchwords
            words = words.trim().split(' ')
            words = words.filter(w => w != '')
            return words
        })
        // END DESIGN

        // CONTENT

        const setFullName = (val) => {
            store.commit('setDeceasedName', val || '')
        };

        const fullNameComp = computed(() => {
            return project_subject.value || store.getters.getDeceasedName;
        });

        const selectNewImage = (id) => {
            store.commit('setImageId', id);
            if(store.getters.isProjectshare)
                submitInquiry();
        }

        const selectNewEffect = (id) => {
            store.commit('setEffectId', id);
            if(store.getters.isProjectshare)
                submitInquiry();
        }

        const selectNewPosition = (id) => {
            store.commit('setPositionId', id);
            if(store.getters.isProjectshare)
                submitInquiry();
        }

        const selectNewImagePosition = (id) => {
            store.commit('setImagePosition', id);
            if(store.getters.isProjectshare)
                submitInquiry();
        }

        const selectNewTypography = (id) => {
            store.commit('setTypographyId', id);
            if(store.getters.isProjectshare)
                submitInquiry();
        }

        const getEffectRectangleStyle = (name) => {
            const style = getEffectStyle(name);
            style.height= '100%';
            style.width= '50%';
            style.margin= '0 auto';
            return style;
        };

        // END CONTENT

        // NAVIGATION
        const navigate = (component) => {
            if (component === "designs") {
                let query = {};
                if (route.query.search) {
                    query = { search: route.query.search };
                } else if (route.query.categoryId) {
                    query = { categoryId: route.query.categoryId };
                }

                return router.push({ name: component, query });
            }
            return router.push({ name: component });
        };

        const search = (searchword) => {
            store.commit('setDesignState', { scrollY : 0, resultSize: 0 })
            return router.push({ name: "designs", query: { search: searchword } });
        };

        const categoryClicked = (cat) => {
            store.commit('setDesignState', { scrollY : 0, resultSize: 0 })
            return router.push({ name: "designs", query: { categoryId: cat.id } });
        };

        // END NAVIGATION

        // FONTS
        const fonts = computed(() => {
            return store.state.fonts;
        });
        const selectedNameFont = computed(() => {
            return store.state.fonts.dead_name?.find(font=>font.id == store.getters.getNameFontId);
        });
        const selectedTextFont = computed(() => {
            return store.state.fonts.text?.find(font=>font.id == store.getters.getTextFontId);
        });

        const createFontFace = (font) => {
            if (!font) return;
            const getStyleTag = document.getElementById(font.id);
            if(getStyleTag) return ;

            let fontFace = `@font-face {
                        font-family: 'im4 ${font["font-family"]}';
                        font-style: ${font["font-style"]};
                        font-weight: ${font["font-weight"]};
                        font-display: swap;
                        src: url('${font["url"]}');
                    }`;

            let style = document.createElement("style");
            style.id = font.id;
            style.innerHTML = fontFace;
            document.getElementsByTagName("head")[0].appendChild(style);
        };

        const fontChange = (selectedFont, target, submit = true) => {
            let font = selectedFont ? selectedFont : fonts.value[target]?.find((font) => font.default);
            if(target === 'dead_name')
                store.commit('setNameFontId', font?.id);
            else if(target === 'text')
                store.commit('setTextFontId', font?.id);
            createFontFace(font);

            if(submit && store.getters.isProjectshare)
                submitInquiry();
        };

        // END FONTS

        // INQUIRY

        const showShare = ref(false);
        const showForm = ref(false);

        const toggleForm = () => {
            showForm.value = !showForm.value;
        };

        const shareButton = computed(() => {
            if (store.state.inquiry.inquiryKey || route.query.key)
                return { icon: "save", text: translate("inspo.save_design") };
            return { icon: "share-from-square", text: translate("inspo.share_design") };
        });

        const shareDesign = () => {
            showShare.value = true;
        };

        const submitInquiry = async () => {
            await store.dispatch('submitInquiry', {
                designId: id,
                shareOnly: true,
            });
        };

        // END INQUIRY

        const canSetPreferredDesign = computed(()=>{
            return store.getters.isProjectshare && store.state.can_set_preferred_design && !store.state.preferred_design_ids.includes(id);
        })

        const setPreferredDesign = () => {
            dispatchEvent(new CustomEvent('inspopreferreddesign', {
                bubbles: true,
                composed: true,
                detail: id
            }))
        }

        let root = null;
        const shadowRoot = () => {
            if(root == null)
                root = document.getElementById("im4-inspiration-embed").shadowRoot.getElementById("im4-inspiration-embed");
            return root;
        };


        const resizeCount = ref(0);
        const onResize = async () => {
            const canvasWrapper = shadowRoot().querySelector(".preview-image-wrapper");
            if(!canvasWrapper)
                return;

            const canvas = shadowRoot().querySelector(".preview-image");
            const canvasImage = shadowRoot().querySelector("#design-background");
            const content = shadowRoot().querySelector(".preview-content");

            let width = canvas.getBoundingClientRect().width;
            let height = `${(width * 71.3) / 100}`; //  71.3%, decided height ratio

            initFontSizeName.value = Math.round(height * 5) / 100
            initFontSizeText.value = Math.round(height * 2.5) / 100

            let contentHeight = height;
            if(store.state.size === 'medium' && height < 500) {
                contentHeight = 500;
                previewOnBackground.value = true;
            } else
                previewOnBackground.value = false;

            if (store.state.size === 'small')
                contentHeight = height = "fit-content";
            else {
                height += 'px';
                contentHeight += 'px';
            }

            canvasWrapper.style.height = contentHeight;
            canvas.style.height = height;
            canvasImage.style.height = height;
            content.style.height = contentHeight;

            if(resizeCount.value < 10) {
                resizeCount.value += 1;
                setTimeout(onResize, 50);
            }
        };

        onMounted(async ()=> {
            fontChange(selectedNameFont.value, "dead_name", false);
            fontChange(selectedTextFont.value, "text", false);
            window.addEventListener("resize", onResize);

            setTimeout(scrollToTop, 100);
        });

        onBeforeUnmount(()=>{
            window.removeEventListener("resize", onResize);
        });

        watch(() => route.query.editMode, async (val)=> {
            editMode.value = is_true(val) && !store.state.edit_disabled;
            store.commit("setDesignState", {editMode: editMode.value});
            await nextTick();
            onResize();
        }, { immediate: true});

        watch(() => route.query.showFeed, async (val) => {
            store.commit("setDesignState", {showFeed: is_true(val) });
            await nextTick();
            onResize();
        }, { immediate: true});

        return {
            store,
            route,
            translate,
            nl2br,
            id,
            settings,
            typographies,
            selectNewTypography,
            editMode,
            editBtn,
            toggleEdit,
            showFeed,
            toggleFeed,
            effects,
            getEffectRectangleStyle,
            positions,
            getPositionStyle,
            images,
            image_positions,
            design,
            filteredDesigns,
            searchwords,
            navigate,
            search,
            categoryClicked,
            fonts,
            selectedNameFont,
            selectedTextFont,
            fontChange,
            setFullName,
            fullNameComp,
            showShare,
            showForm,
            toggleForm,
            shareDesign,
            shareButton,
            project_subject,
            hasProjectSubjectImage,
            selectNewPosition,
            selectNewEffect,
            selectNewImage,
            selectNewImagePosition,
            initFontSizeText,
            initFontSizeName,
            canSetPreferredDesign,
            setPreferredDesign,
            previewOnBackground,
        };
    },
    async beforeRouteEnter(to, from, next) {
        await ensureValidRoute(to, from, next);
    },
    async beforeRouteUpdate(to, from, next) {
        await ensureValidRoute(to, from, next);
    },
};

async function ensureValidRoute(to, from, next) {
    if (!store.state.fonts.dead_name) await store.dispatch("getFonts");
    if(Object.keys(store.state.settings).length === 0)
        await store.dispatch("getSettings");

    let designId = to.params?.id;
    if(to.query.key) {
        if(store.state.inquiry.inquiryKey !== to.query.key || store.state.inquiry.inquiry.default)
            await store.dispatch("getInquiry", to.query.key);

        if(!store.getters.isProjectshare) {
            const inquiryDesignId = store.state.inquiry.inquiry?.json?.design_id;
            if (inquiryDesignId)
                designId = inquiryDesignId;
        }
    }

    next(async(vm)=>{
        if(!designId) {
            vm.$router.replace({name: "designs"});
        } else {
            const design = await store.dispatch('getDesign', designId);
            if (!design)
                vm.$router.replace({name: "designs"});
        }

        if(to.params?.id != designId) {
            vm.$router.replace({
                name: "design",
                params: {
                    id: designId,
                },
                query: to.query,
            });
        }
    });
}

</script>

<style scoped >

.design-desc-wrapper {
    display: flex;
    flex-direction: column-reverse;
}

.design-desc {
    margin-bottom: .8em;
}

.edit-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}


.design-buttons-small{
    width: 100%;
    justify-content: center;
    margin-bottom: .8em;
}

.spread-select  {
    display: flex;
    gap: .5em;
    padding-top: .3em;
    height: 100%;
    justify-content: center;
}

.spread-select img {
    width: fit-content;
    object-fit: cover;
    cursor: pointer;
    box-shadow: none;
    user-select: none;
}

hr{
    opacity: 0.2;
    width: 95%;
}

.tags-wrapper {
    padding: .2em 0;
}
.tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.tags p{
    font-size: .85em;
    margin: 0 .25em;
    cursor: pointer;
    color: var(--secondary-text);
}
.tags p:hover {
    text-decoration: underline;
}

.choose-bg-wrapper {
    max-width:230px;
}

.choose-bg-wrapper .info-text {
    margin-top: 0;
}

.rectangles:not(.nowrap) {
    display: flex;
    flex-wrap: wrap;
}
.rectangles.typography {
    justify-content: center;
    margin-top: .5em;
}
.rectangle-wrapper{
    position: relative;
    min-width:3.38em;
    min-height:3.38em;
    margin: 0.25em;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-100);
    border-radius: 0.5em;
    border-width: 0;
}
.rectangle {
    position: absolute;
    height: 75%;
    width: 40%;
    border: 1px solid var(--gray-900);
    background-color: var(--gray-900);
}
.rectangle-wrapper.arrow svg,
.rectangle-wrapper.image svg {
    height: 1.3em;
}
.rectangle-wrapper.image.person svg {
    height: 1.5em;
}

.rectangles .position-boxes {
    display: flex;
    flex-direction: column;
}

.rectangles .position-boxes .box {
    background-color: var(--gray-100);
    width: 80%;
    margin: 0.05em auto;
    height: 0.5em;
}

.edit-content {
    position: relative;
}

.edit-content,
.edit-content p {
    text-align: left;
    margin: 0;
    padding: 0;
}
.edit-content label {
    font-size: 0.8em;
}

.spread-select-item{
    border: 3px solid rgba(0,0,0,0.03) !important;
}
.spread-select-item.selectable.selected {
    border: 3px solid var(--primary) !important;
}

.nobg-spread{
    background: white;
}

.mq-medium .spread-select {
    gap: .3em;
}

.mq-medium .rectangle-wrapper{
    min-width:2.4em;
    min-height:2em;
}

.mq-medium .tags p{
    font-size: .75em;
    margin: 0 .20em;
}

.mq-medium .info-text {
    font-size: .75em;
}

.mq-medium .input-light {
    font-size: 90%;
}
.mq-medium .editor-info{
    margin-top: .8em;
}

.mq-medium .design-desc-wrapper {
    flex-direction: column;
}
.mq-medium .rectangle-wrapper{
    height: max(5vh, 3em);
}


</style>
