<template>
    <section class="inspiration w-100" :class="{projectshare: store.getters.isProjectshare}">
        <TopBar v-if="!store.getters.isProjectshare" :title="translate('inspo.inspiration')">
            <template v-slot:topbar-end>
                <Btn icon="search"
                     :label="translate('inspo.search_designs')"
                     :clickFunction="() => navigate('designs')"
                     :smallerText="store.state.size !== 'large'"
                />
            </template>
        </TopBar>

        <div v-if="store.state.errorOccured">
            <ErrorOccured />
        </div>
        <section class="items-wrapper">
            <button class="item" v-for="inspiration in inspirationPaginated" :key="inspiration.id" @click="openInspiration(inspiration)">
                <img :style="{objectPosition: objectPosition(inspiration.data)}" :src="inspiration.image_url" :alt="inspiration.title">
                <span>{{ inspiration.title + ' (' + inspiration.id + ') ' }}</span>
            </button>
            <section class="item pagination"> 
                <div :style="{visibility: showPrevPageButton ? 'visible' : 'hidden'}" class="back-button" @click="showPrev">
                    <font-awesome-icon height="12" :icon="['far', 'chevron-left']"/>
                    <button>{{ translate("inspo.previous_page") }}</button>
                </div>
                <div class="number-btns" >
                    <div v-for="btn in buttonCount" :key="btn" @click="setIndex(btn)">
                        <button v-if="btn == selectedIndex" :style="selectedButtonStyle">{{btn}}</button>
                        <button v-else>{{btn}}</button>
                    </div>
                </div>
                <div :style="{visibility: showNextPageButton ? 'visible' : 'hidden'}" class="next-button" @click="showMore">
                    <button>{{ translate("inspo.next_page") }}</button>
                    <font-awesome-icon height="12" :icon="['far', 'chevron-right']"/>
                </div>
            </section>
            <div v-if="!settings.global_master && !showPrevPageButton " class="credit">
                <p v-html="translate('inspo.credit')"></p>
            </div>
        </section>
    </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, computed, onBeforeMount } from 'vue'
import { translate } from "@/libs/helper";

import Btn from '../components/Btn.ce.vue';
import TopBar from '../components/TopBar.ce.vue';
import ErrorOccured from '../components/ErrorOccured.ce.vue';
import store from '../store/index'

export default {
    components: {
        Btn,
        TopBar,
        ErrorOccured,
    },
    setup() {
        const router = useRouter()
        const store = useStore()

        store.commit("setDesignState", {editMode: false});

        const inspirationPerPage = ref(6)
        const inspirationPerPageEnd = ref(6)
        const inspirationCounter= ref(0)

        const settings = computed(() => {
            return store.state.settings
        })

        const inspirations = computed(() => {
            return store.state.inspiration.items
        })

        const buttonCount = computed(() => {
            return inspirations.value.length ? Math.ceil(inspirations.value?.length / inspirationPerPage.value) : 0
        })

        const inspirationPaginated = computed(() => {
            return inspirations.value.slice(inspirationCounter.value, inspirationPerPageEnd.value)
        })

        const showPrevPageButton = computed(() => {
            return inspirationCounter.value !== 0;
        })

        const showNextPageButton = computed(() => {
            return inspirationPerPageEnd.value < inspirations.value.length;
        })

        const selectedIndex = computed(() => {
            return Math.ceil(inspirationPerPageEnd.value / inspirationPerPage.value)
        })

        const selectedButtonStyle = computed(() => {
            return {borderBottom: '2px solid black', padding: '0 10px 6px'}
        })

        const openInspiration = async (inspiration) => {
            return router.push({ name: 'inspiration', params: { id: inspiration.id }})
        }

        const showMore = () => {
            inspirationCounter.value += inspirationPerPage.value
            inspirationPerPageEnd.value += inspirationPerPage.value
        }

        const showPrev = () => {
            inspirationCounter.value -= inspirationPerPage.value
            inspirationPerPageEnd.value -= inspirationPerPage.value
        }

        const setIndex = (index) => {
            inspirationCounter.value = (inspirationPerPage.value * index) - inspirationPerPage.value 
            inspirationPerPageEnd.value = inspirationPerPage.value * index
        }

        const objectPosition = (data) => {
            let position = {focusX: '50', focusY: '50'}
            if(data.focusX) {
                position.focusX = data.focusX
            }

            if(data.focusY) {
                position.focusY = data.focusY
            }
            return position.focusX + '% ' + position.focusY + '%'
        }

        const rememberIndex = () => {
            store.commit("setSelectedIndex", selectedIndex.value)
        }
        
        const navigate = (component) => {
            return router.push({ name: component});
        }

        onBeforeMount(async ()=>{
            if(!inspirations.value.length)
                await store.dispatch('getInspirations');
        })

        return {
            translate,
            store,
            inspirationPaginated,
            openInspiration,
            navigate,
            buttonCount,
            showPrevPageButton,
            showNextPageButton,
            showMore,
            showPrev,
            setIndex,
            selectedIndex,
            selectedButtonStyle,
            objectPosition,
            rememberIndex,
            settings
        }
    },
    beforeRouteEnter(to, from, next) {
        if(Object.keys(store.state.settings).length === 0)
            store.dispatch('getSettings');
        
        next(vm => {
            if(from.name === 'inspiration') {
                vm.setIndex(store.state.inspiration.selectedIndex)
            }
        })
    },
    beforeRouteLeave(to, from, next) {
        if(to.name === 'inspiration') {
            this.rememberIndex()
        }
        next()
    }
}
</script>

<style scoped>
    .items-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, minmax(8em, 16vh)) auto;
        grid-gap: 24px 10px;
        margin: 16px 0 0;
    }

    .projectshare .items-wrapper{
        margin: 0;
    }

    .mq-medium .items-wrapper {
        grid-template-rows: repeat(3, minmax(14em, 16vh)) auto;
        grid-gap: 24px 15px;
    }

    .item {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 100%;
        grid-template-rows: 100% 2em;
        cursor: pointer;
    }

    .item img {
        object-fit: cover; /* or object-fit: contain; */
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px;
    }

    .item p {
        margin: 0 16px;
        font-size: 12px;
        font-weight: 300;
        color: var(--gray-900);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        height: 2em;
        line-height: 2;
        text-align: center;
    }

    .item.pagination {
        grid-column: 1 / -1;
        grid-row: 4 / 4;
    }

    .pagination {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .pagination button {
        white-space: nowrap;
        font-size: 12px;
    }

    .pagination img {
        object-fit: none;
    }

    .number-btns {
        display: flex;
    }

    .number-btns button{
        margin: 0 8px;
    }

    .back-button,
    .next-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .credit {
        grid-row: 8 / -1;
        grid-column: 1 / -1;
        align-self: flex-start;
    }
    .credit p {
        margin: 0;
        padding: 0;
        font-size: .75em;
        font-weight: 300;
        text-align: center;
    }

    .mq-large .credit {
        grid-column: auto;
    }

    .mq-large .credit p {
        margin: -60px 0 0;
        text-align: left;
    }

    .mq-large .items-wrapper {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 5vw) auto;
        grid-gap: 30px 15px;
    }

    .mq-large.mq-medium-large .items-wrapper {
        grid-template-rows: repeat(6, 4vw) auto;
    }

    @media only screen and (min-width: 2000px) {
        .mq-large .items-wrapper {
            grid-template-rows: repeat(6, 4vw) auto;
        }
    }
    @media only screen and (min-width: 2500px) {
        .mq-large .items-wrapper {
            grid-template-rows: repeat(6, 3vw) auto;
        }
    }
    @media only screen and (min-width: 3200px) {
        .mq-large .items-wrapper {
            grid-template-rows: repeat(6, 2vw) auto;
        }
    }

    .mq-large .item:nth-child(1) {
        grid-column: 1 / 3;
        grid-row: 1 / 7;
    }

    .mq-large .item:nth-child(2) {
        grid-column: 3 / 5;
        grid-row: 1 / 4;
    }

    .mq-large .item:nth-child(3) {
        grid-column: 5 / -1;
        grid-row: 1 / 4;
    }

    .mq-large .item:nth-child(4) {
        grid-column: 3 / 4;
        grid-row: 4 / 7;
    }

    .mq-large .item:nth-child(5) {
        grid-column: 4 / 6;
        grid-row: 4 / 7;
    }

    .mq-large .item:nth-child(6) {
        grid-column: 6 / 6;
        grid-row: 4 / 7;
    }

    .mq-large .item.pagination {
        grid-row: 7 / -1;
        grid-column: 1 / -1;
    }

</style>